body,
html {
    font-family: 'Cairo'

  /* 👇️ or use Operating system default fonts 👇️ */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
}
.root{
    font-family: 'Cairo'
}